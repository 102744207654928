import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const state = {
    'user': {
        user: {},
        token: null,  // Start with null, token will be handled by persisted state
        refreshToken: null
    },
    'activation': {
        activation: {}
    },
    'patients': {
        patients: {}
    },
    'signup': {
        signup: {},
        contact: {},
        phone: {},
        medicalQuestionnaire: {}
    },
    'client_order': {
        clientOrder: {}
    },
    'env': {
        urls: {}
    },
    'website': {
        website: {}
    },
    'translation': {
        language: 'en-us',
        translation: {}
    }
};

// Function to check if localStorage is available
function isLocalStorageAvailable() {
    try {
        const testKey = 'test';
        localStorage.setItem(testKey, 'test');
        localStorage.removeItem(testKey);
        console.log("LocalStorage is available");
        return true;
    } catch (e) {
        console.error("LocalStorage is not available:", e.message);
        return false;
    }
}

// Safely manage localStorage persistence with fallback
const storageMethod = isLocalStorageAvailable() ? {
    getItem: (key) => {
        try {
            return window.localStorage.getItem(key);
        } catch (error) {
            console.error(`Error accessing localStorage.getItem for key ${key}:`, error);
            return null;
        }
    },
    setItem: (key, value) => {
        try {
            window.localStorage.setItem(key, value);
        } catch (error) {
            console.error(`Error accessing localStorage.setItem for key ${key}:`, error);
        }
    },
    removeItem: (key) => {
        try {
            window.localStorage.removeItem(key);
        } catch (error) {
            console.error(`Error accessing localStorage.removeItem for key ${key}:`, error);
        }
    }
} : {
    getItem: () => null,  // No-op if localStorage is unavailable
    setItem: () => {},
    removeItem: () => {}
};

const store = new Vuex.Store({
    state,
    getters: {
        getUser: (state) => state.user.user,
        getActivation: (state) => state.activation.activation,
        getPatients: (state) => state.patients.patients,
        getSignup: (state) => state.signup.signup,
        getContact: (state) => state.signup.contact,
        getPhone: (state) => state.signup.phone,
        getMedical: (state) => state.signup.medicalQuestionnaire,
        getClientOrder: (state) => state.client_order.clientOrder,
        getToken: (state) => state.user.token,
        getRefreshToken: (state) => state.user.refreshToken,
        getEnv: (state) => state.env.urls,
        getWebsite: (state) => state.website.website,
        getTranslation: (state) => state.translation.translation,
        getLanguage: (state) => state.translation.language,
        isAuthenticated: (state) => !!state.user.token,
        isActivated: (state) => !!state.activation.activation,
        isPatients: (state) => !!state.patients.patients,
        isSignedup: (state) => !!state.signup.signup,
        isContact: (state) => !!state.signup.contact,
        isPhone: (state) => !!state.signup.phone,
        isMedical: (state) => !!state.signup.medicalQuestionnaire,
        isClientOrder: (state) => !!state.client_order.clientOrder,
    },
    actions: {
        setUser({ commit }, user) {
            commit('setUser', user);
        },
        setToken({ commit }, token) {
            commit('setToken', token);
        },
        setRefreshToken({ commit }, token) {
            commit('setRefreshToken', token);
        },
        setPatients({ commit }, patients) {
            commit('setPatients', patients);
        },
        setActivation({ commit }, activation) {
            commit('setActivation', activation);
        },
        setSignup({ commit }, signup) {
            commit('setSignup', signup);
        },
        setContact({ commit }, contact) {
            commit('setContact', contact);
        },
        setPhone({ commit }, phone) {
            commit('setPhone', phone);
        },
        setMedical({ commit }, medicalQuestionnaire) {
            commit('setMedical', medicalQuestionnaire);
        },
        setClientOrder({ commit }, clientOrder) {
            commit('setClientOrder', clientOrder);
        },
        setEnv({ commit }, env) {
            commit('setEnv', env);
        },
        setWebsite({ commit }, website) {
            commit('setWebsite', website);
        },
        setTranslation({ commit }, translation) {
            commit('setTranslation', translation);
        },
        setLanguage({ commit }, language) {
            commit('setLanguage', language);
        },
    },
    mutations: {
        setUser(state, user) {
            state.user.user = user;
        },
        setToken(state, token) {
            state.user.token = token;
        },
        setRefreshToken(state, token) {
            state.user.refreshToken = token;
        },
        setActivation(state, activation) {
            state.activation.activation = activation;
        },
        setPatients(state, patients) {
            state.patients.patients = patients;
        },
        setSignup(state, signup) {
            state.signup.signup = signup;
        },
        setContact(state, contact) {
            state.signup.contact = contact;
        },
        setPhone(state, phone) {
            state.signup.phone = phone;
        },
        setMedical(state, medicalQuestionnaire) {
            state.signup.medicalQuestionnaire = medicalQuestionnaire;
        },
        setClientOrder(state, clientOrder) {
            state.client_order.clientOrder = clientOrder;
        },
        setEnv(state, env) {
            state.env.urls = env;
        },
        setWebsite(state, website) {
            state.website.website = website;
        },
        setTranslation(state, translation) {
            state.translation.translation = translation;
        },
        setLanguage(state, language) {
            state.translation.language = language;
        },
    },
    plugins: [
        createPersistedState({
            storage: storageMethod // Use safe storage method
        })
    ]
});

export default store;
