var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoggedIn)?_c('a-page-header',{staticClass:"app-page-title",staticStyle:{"margin":"0 0 24px"},attrs:{"title":_vm.t('Replace Your Kit'),"ghost":false}}):_vm._e(),_c('a-card',{staticClass:"post-submit with-border",staticStyle:{"display":"none"},attrs:{"bordered":false}},[_c('a-row',[_c('a-col',{staticClass:"form-container"},[_c('a-result',{attrs:{"status":"success","title":('Shipping address confirmed!'),"sub-title":_vm.t('Please follow the instructions in the kit carefully to recollect your sample.')},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('a-button',{key:"console",attrs:{"type":"primary","size":"large"},on:{"click":_vm.handleAccountClick}},[_vm._v(" "+_vm._s(_vm.t('Back to My Account'))+" ")])]},proxy:true}])})],1)],1)],1),_c('a-card',{staticClass:"pre-submit with-border",attrs:{"bordered":false}},[_c('a-row',[_c('a-col',{staticClass:"instruction-container"},[_c('p',{staticClass:"sub-title",staticStyle:{"font-weight":"bold","font-size":"120%"}}),_c('p',[_vm._v(_vm._s(_vm.t('We are happy to send you a replacement kit at no cost. To receive your replacement kit, please complete the kit replacement form below.')))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.ifYouNeedHelpText)}})])],1),_c('a-row',[_c('a-col',{staticClass:"form-container"},[(_vm.error)?_c('error',{attrs:{"error":_vm.error}}):_vm._e(),_c('a-form',{staticClass:"general-form",attrs:{"id":"form-general","form":_vm.formReplaceKit,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleReplaceKitSubmit.apply(null, arguments)}}},[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"xs":{ span: 24 },"md":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":"First Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'first_name',
                                        {
                                            initialValue: this.user.first_name,
                                            rules: [{ required: false }] 
                                        }
                                    ]),expression:"[\n                                        'first_name',\n                                        {\n                                            initialValue: this.user.first_name,\n                                            rules: [{ required: false }] \n                                        }\n                                    ]"}],attrs:{"placeholder":"","size":"large"}})],1)],1),_c('a-col',{attrs:{"xs":{ span: 24 },"md":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":"Last Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'last_name',
                                        {
                                            initialValue: this.user.last_name,
                                            rules: [{ required: false }] 
                                        }
                                    ]),expression:"[\n                                        'last_name',\n                                        {\n                                            initialValue: this.user.last_name,\n                                            rules: [{ required: false }] \n                                        }\n                                    ]"}],attrs:{"placeholder":"","size":"large"}})],1)],1)],1),_c('a-form-item',{attrs:{"label":"Address Line 1"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'address_1',
                                {
                                    initialValue: _vm.isLoggedIn ? _vm.user.address_1 : '',
                                    rules: [{ required: true, message: 'Please enter your address.' }]
                                },
                            ]),expression:"[\n                            'address_1',\n                                {\n                                    initialValue: isLoggedIn ? user.address_1 : '',\n                                    rules: [{ required: true, message: 'Please enter your address.' }]\n                                },\n                            ]"}],attrs:{"placeholder":"","size":"large"}})],1),_c('a-form-item',{attrs:{"label":"Address Line 2"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'address_2',
                                {
                                    initialValue: _vm.isLoggedIn ? _vm.user.address_2 : '',
                                    rules: [{ required: false }]
                                },
                            ]),expression:"[\n                            'address_2',\n                                {\n                                    initialValue: isLoggedIn ? user.address_2 : '',\n                                    rules: [{ required: false }]\n                                },\n                            ]"}],attrs:{"placeholder":"","size":"large"}})],1),_c('a-form-item',{attrs:{"label":"Country"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'country_id',
                                {
                                    initialValue: _vm.isLoggedIn ? _vm.user.country.id : 226 + '',
                                    rules: [
                                        { required: true, message: 'Please select a country.' },
                                        { validator: _vm.checkCountry }
                                    ]
                                }
                            ]),expression:"[\n                                'country_id',\n                                {\n                                    initialValue: isLoggedIn ? user.country.id : 226 + '',\n                                    rules: [\n                                        { required: true, message: 'Please select a country.' },\n                                        { validator: checkCountry }\n                                    ]\n                                }\n                            ]"}],attrs:{"show-search":"","placeholder":"Select one","filter-option":_vm.filterOption,"option-filter-prop":"children","size":"large"},on:{"change":_vm.handleCountryChange}},_vm._l((_vm.countries),function(data){return _c('a-select-option',{key:data.key},[_vm._v(" "+_vm._s(data.value)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"State","data-label":"state_id"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'state_id',
                                {
                                    rules: [
                                        { required: _vm.isStateIdRequired, message: 'Please select a state/province.' }
                                    ] 
                                }
                            ]),expression:"[\n                                'state_id',\n                                {\n                                    rules: [\n                                        { required: isStateIdRequired, message: 'Please select a state/province.' }\n                                    ] \n                                }\n                            ]"}],attrs:{"show-search":"","placeholder":"Select one","filter-option":_vm.filterOption,"option-filter-prop":"children","size":"large"}},_vm._l((_vm.states),function(data){return _c('a-select-option',{key:data.key},[_vm._v(" "+_vm._s(data.value)+" ")])}),1),(_vm.errors['state_id'])?_c('div',{staticStyle:{"color":"red","margin-top":"5px"}},[_vm._v(" "+_vm._s(_vm.errors['state_id'])+" ")]):_vm._e()],1),_c('a-form-item',{attrs:{"label":"State","data-label":"state"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'state_caption',
                                {
                                    rules: [{ required: false }]
                                },
                            ]),expression:"[\n                                'state_caption',\n                                {\n                                    rules: [{ required: false }]\n                                },\n                            ]"}],attrs:{"placeholder":"","size":"large"}})],1),_c('a-form-item',{attrs:{"label":"City"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'city',
                                {
                                    initialValue: _vm.isLoggedIn ? _vm.user.city : '',
                                    rules: [{ required: true, message: 'Please enter your city.' }]
                                },
                            ]),expression:"[\n                            'city',\n                                {\n                                    initialValue: isLoggedIn ? user.city : '',\n                                    rules: [{ required: true, message: 'Please enter your city.' }]\n                                },\n                            ]"}],attrs:{"placeholder":"","size":"large"}})],1),_c('a-form-item',{attrs:{"label":"Zip Code","data-label":"zip_code"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'zip_code',
                                {
                                    initialValue: _vm.isLoggedIn ? _vm.user.postal_code : '',
                                    rules: [{ required: true, message: 'Please enter your zip/postal code.' }]
                                },
                            ]),expression:"[\n                            'zip_code',\n                                {\n                                    initialValue: isLoggedIn ? user.postal_code : '',\n                                    rules: [{ required: true, message: 'Please enter your zip/postal code.' }]\n                                },\n                            ]"}],attrs:{"placeholder":"","size":"large"}})],1),_c('a-button',{staticClass:"button-submit",style:(_vm.website ? 'background-color:' + _vm.website.rgb.button + '; border-color:' + _vm.website.rgb.button  : ''),attrs:{"disabled":_vm.formSubmitting,"loading":_vm.formSubmitting,"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.formSubmitting ? 'Submitting...' : 'Replace Kit')+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }