<template>
    <div>

        <a-row>

            <a-col class="app-signup" style="text-align:center;">
                <h2 style="margin-bottom:30px;">Cookies are required</h2>
                <p style="margin-bottom:20px;">
                    Please enable cookies in your browser settings and refresh the page to continue.
                </p>
                <p><a href="{{ loginLink }}">{{ t('Back to Log in') }}</a></p>
                
            </a-col>
            
        </a-row>
    </div>
</template>


<script>

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'MainenanceComponent',
        components: {
        },
        computed: {
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                loginLink: '/' + this.regionId + '/' + this.websiteId + '/login',
                t: t
            }
        },
        mounted: function() {
        },
        methods: {
        }
    }
</script>